@font-face {
  font-family: 'TomatoGrotesk';
  src: url('../theme/font/TomatoGrotesk-Regular.woff') format('woff'),
    url('../theme/font/TomatoGrotesk-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'TomatoGrotesk500';
  src: url('../theme/font/TomatoGrotesk-Regular.woff') format('woff'),
    url('../theme/font/TomatoGrotesk-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'TomatoGroteskbold';
  src: url('../theme/font/AnyConv.com__TomatoGrotesk-Bold.woff') format('woff'),
    url('../theme/font/TomatoGrotesk-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Oxanium';
  src: url('../theme/font/Oxanium-VariableFont_wght.woff') format('woff'),
    url('../theme/font/Oxanium-VariableFont_wght.woff2') format('woff2');
}

* {
  font-family: 'Oxanium' !important;
  // background-color: #121212 !important;
}

:root {
  // --primary-color: #ef4923;
  --primary-color: #f8971c;
  --secondary-color: #ffefec;
  --black-color: #121212;
}

body {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */
}

body::-webkit-scrollbar {
  width: 12px;
  /* Width of the vertical scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Color of the thumb */
}

body::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the track */
}

.bg-color {
  background-color: var(--black-color);
}

.nav-text {
  color: var(--secondary-color) !important;
}

// searching
/* Target the input element with the "search-input" class */
.search-input {
  // border: none; /* Remove the default border */
  outline: none;
  /* Remove the outline when focused */
}

.search-btn {
  border: none;
  background-color: transparent;
}

.main-banner {
  .slick-slider {
    .slick-dots {
      button::before {
        color: #f89727;

        // Additional styles for .slick-active state
        .slick-active & {
          color: #f89727;
        }
      }
    }
  }
}

.product-description-single-view {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.location-box-web {
  height: 80px !important;
}

.featured-text {
  color: #ffa800;
  font-size: 20px;
  font-weight: bold;
  line-height: 17.5px;
}

.featured-img {
  height: 210px;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 75%) 100%);
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding-top: 17px;
}

.featured-img-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
}

.vehicle-brand {
  .slick-list {
    .slick-track {
      width: 100% !important;
    }
  }
}

.w100 {
  width: 100% !important;
}

.brand-img {
  height: 40px;
  object-fit: contain;
}

.all-brand-btn {
  background-color: #1f1f1f;
  color: #d0d0d0;
  // width: 265px;
  font-weight: bolder;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.9);
}

.feat-text {
  color: #5b5b5b;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}

.feat-text-sub {
  color: #ffa800;
  font-weight: bolder;
  text-align: center;
}

.feat-text-des {
  color: #e3e3e3;
  font-weight: 400;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.feat-img {
  height: 100px;
}

.prod-div {
  border-radius: 50%;
  height: 2px;
  margin-top: 13px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(0, 0, 0, 0) 100%);
}

.prod-div.prod-div-utube {
  @media screen and (min-width: 500px) {
    margin-top: 60px;
    width: 60%;
    margin-left: 120px;
  }
}

.car-year {
  position: absolute;
  top: 10px;
  background-color: #f89724;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px;
  width: 45px;
}

.react-player {
  // margin: 6rem 0;
  position: relative;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 40px;

  @media screen and (max-width: 475px) {
    margin-bottom: 3rem;
    margin-top: 1rem;
    // width: 100%;
    height: 250px;

    .react-player-video {
      width: 100%;
      height: 100%;

      div {
        width: 100% !important;
      }
    }
  }

  .react-player-video {
    z-index: 1;

    iframe {
      border-radius: 44.6498px;

      @media screen and (max-width: 475px) {
        // border-radius: 21.5311px;
        border-radius: 20px;
        height: 200px;
        width: 340px;
      }
    }

    .react-player__preview {
      border-radius: 44.6498px;

      @media screen and (max-width: 768px) {
        // border-radius: 21.5311px;
        border-radius: 0;
      }
    }
  }

  .ytp-chrome-top {
    display: none !important;
  }
}

.search-field {
  background-color: #1f1f1f;

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #1f1f1f;
      color: white;
    }
  }

  .css-g0vbem {
    border-color: #1f1f1f;
    color: white;
  }

  .css-yzm7vx {
    padding-left: 40px !important;
    border-color: #1f1f1f;
    border-radius: 0px;
  }
}

.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  color: white;
}

.css-1xf3bi4-MuiInputBase-root-MuiOutlinedInput-root {
  padding-left: 25px;
  border-color: #1f1f1f;
  border-radius: 0px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #1f1f1f;
}

.custom-field-sell {
  background-color: #1f1f1f;
  margin-bottom: 10px;

  .css-ze8lza-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(145, 158, 171, 0.32) !important;
  }

  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root {
    .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
      color: white !important;
      height: 15px;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #626060;
    }
  }

  .css-1xf3bi4-MuiInputBase-root-MuiOutlinedInput-root {
    .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
      color: white;
    }
  }

  .css-a7qpy4-MuiInputBase-root-MuiOutlinedInput-root {
    .css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
      color: white;
      font-size: 15px;
    }
  }

  .css-1sp8c2x-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1sp8c2x-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1sp8c2x-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: white;
  }

  .css-yzm7vx {
    color: white !important;
    height: 15px;
  }

  .css-zuwxiq {
    color: white !important;
  }

  .css-p8k10j.css-p8k10j.css-p8k10j {
    color: white !important;
  }
}

.css-ac28eu-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #121212;
  color: white;
}

.css-1d6ffrd {
  background-color: #121212;
  color: white;
}

.filter-drawer {
  .css-1bb6vmr-MuiPaper-root-MuiDrawer-paper {
    width: 100%;
  }

  .css-mdua1w {
    width: 100%;
  }
}

.nav-drawer {
  .css-mdua1w {
    width: 'auto';
  }
}

.filter-search {
  background-color: #1f1f1f;

  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #ffa800;
    }
  }

  .css-1xf3bi4-MuiInputBase-root-MuiOutlinedInput-root {
    .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
      height: 10px;
      color: white;
      padding-left: 40px;
    }
  }

  .css-yzm7vx {
    height: 10px;
    padding-left: 40px;
    color: white;
  }
}

.app-bar.css-1u6n7j5-MuiGrid-root {
  width: unset;
  margin-left: 0px;
}

.related-prod {
  width: 98% !important;
}

.p-2 {
  padding-right: 5px;
}

.head-icon-color {
  color: #ffa800;
}

.head-icon-inActiveColor {
  color: white;
}

.review-card {
  width: 95% !important;
}

.cat-scroller {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.cat-scroller::-webkit-scrollbar {
  display: none;
}

/* Optionally, you can add some additional styling to make it visually appealing */
.search-input:focus {
  // border: 1px solid #ccc; /* Add a border when the input is focused */
  border-radius: 4px;
  /* Add rounded corners */
}

//products page
.pductsstyleroot {
  background-size: cover;
  background-size: cover;
  background-position: center;
  height: 219px;
  // background-color: var(--primary-color);
  // opacity: .5,
}

// common
.zeal-btn-solid {
  background-color: rgba(236, 41, 39, 255);

  &:hover {
    background-color: #be302f;
  }
}

.btn404 {
  background-color: rgba(236, 41, 39, 255);

  &:hover {
    background-color: #be302f;
  }
}

.zeal-btn-outline {
  background-color: white;
  border: 1px solid var(--primary-color);
  color: rgba(236, 41, 39, 255);

  &:hover {
    background-color: #ffdad2;
    border: 1px solid rgba(236, 41, 39, 255);
    color: rgba(236, 41, 39, 255);

    .maingrid {
      position: relative;

      .childgrid {
        top: 7.5rem;
        position: absolute;
        left: 7rem;
      }
    }
  }
}

.popupbtn {
  background-color: rgba(236, 41, 39, 255);

  &:hover {
    background-color: #be302f;
  }

  width: 114px;

  letter-spacing: 1px;
}

.btndiv {
  display: flex;
  justify-content: center;
}

// section 2 style
.Transformmain-typo {
  text-align: center;
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 2rem;
  line-height: 28px;
}

.CardTransform:focus-visible {
  outline: none !important;
}

.CardTransform {
  height: 100%;
  display: flex;
  // flex-direction: column;
  background-color: var(--black-color);
  box-shadow: unset;
  align-items: center;

  // position: relative;
  // border-radius: 13px;
  // margin-right: 10px;
  // width: 250px;
  &.brand {
    margin-right: 0px;
    border-radius: 0px;
  }

  .card-img {
    // height: 134px;
    // width: 114px;
    object-fit: cover;
    // height: 350px;
    aspect-ratio: 4/2.5 !important;
    width: 100%;
    // margin-left: 15px;
    // margin-right: 15px;
    border-radius: 15px;

    @media (max-width: 1024px) {
      object-fit: cover;
      height: 200px;
      width: 100%;
      margin-left: 5px;
      margin-right: 5px;
    }

    @media (max-width: 1024px) {
      object-fit: cover;
      height: 200px;
    }
  }

  .img-brand {
    // height: 134px;
    // width: 114px;
    object-fit: contain;
    width: 50px;
    height: 50px;
  }

  .Card-content {
    // margin: 3px;
    padding: 8px;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .card-content {
    font-size: 24px;
    color: var(--grey-color);
    font-weight: 900 !important;
    text-align: center;
  }
}

.feature-slider {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.CardTransform-feature-cars {
  height: 100%;
  display: flex;
  // flex-direction: column;
  background-color: var(--black-color);
  box-shadow: unset;
  align-items: center;

  // position: relative;
  // border-radius: 13px;
  // margin-right: 10px;
  // width: 250px;
  &.brand {
    margin-right: 0px;
    border-radius: 0px;
  }

  .card-img-feature {
    // height: 134px;
    // width: 114px;
    object-fit: cover;
    height: 200px;
    width: 100%;
    border-radius: 15px;

    @media (max-width: 1024px) {
      object-fit: cover;
      height: 200px;
      width: 100%;
      margin-left: 5px;
      margin-right: 5px;
    }

    @media (max-width: 1024px) {
      object-fit: cover;
      height: 200px;
    }
  }

  .img-brand {
    // height: 134px;
    // width: 114px;
    object-fit: contain;
    width: 50px;
    height: 50px;
  }

  .Card-content {
    // margin: 3px;
    padding: 8px;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .card-content {
    font-size: 24px;
    color: var(--grey-color);
    font-weight: 900 !important;
    text-align: center;
  }
}

.wrapper {
  word-wrap: break-word;
}

.custom-textfield input {
  font-size: 15px;
}

.custom-textfield .MuiInputBase-input {
  color: #d31412;
}

.custom-textfield .MuiOutlinedInput-root {
  border-radius: 0;
  // background-color: #c23b1c;
  // background-color: #d31412;
  border: 1px solid #d31412;
}

.custom-textfield .MuiOutlinedInput-root fieldset {
  border: none;
}

.custom-textfield ::placeholder {
  color: var(--grey-color);
}

.custom-textfield-booking input {
  font-size: 15px;
}

.custom-textfield-booking .MuiInputBase-input {
  color: #090909;
}

.custom-textfield-booking .MuiOutlinedInput-root {
  border-radius: 0;
  background-color: var(--secondary-color);
}

.custom-textfield-booking .MuiOutlinedInput-root fieldset {
  border: 2px solid var(--primary-color);
}

.custom-textfield-booking .MuiOutlinedInput-root :hover {
  border: var(--primary-color);
}

.custom-textfield-booking ::placeholder {
  color: var(--grey-color);
}

.custom-textfield-feedback input {
  font-size: 15px;
}

.custom-textfield-feedback .MuiInputBase-input {
  color: #090909;
}

.custom-textfield-feedback .MuiOutlinedInput-root {
  border-radius: 0;
  background-color: var(--secondary-color);
}

.custom-textfield-feedback .MuiOutlinedInput-root fieldset {
  border: 1px solid var(--primary-color);
}

.custom-textfield-feedback .MuiOutlinedInput-root :hover {
  border: var(--primary-color);
}

.custom-textfield-feedback ::placeholder {
  color: var(--grey-color);
}

.custom-inputlabel {
  color: #5f5c5c;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.lngpgicon {
  margin-right: 8px;
}

#sidebar-inActive {
  transition: all ease-in-out 0.3s;

  width: 65px;

  .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
    width: 65px;
  }

  .css-121b4uz-MuiListItemIcon-root {
    min-width: 48px;
  }
}

#sidebar-active {
  transition: all ease-in-out 0.3s;
  width: 280px;

  .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
    width: 280px;
  }
}

.logo {
  width: 100px;
  height: 50px;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .logo {
    width: 300px;
    height: 80px;
    object-fit: contain;
  }
}

.right-column {
  width: 50% !important;
}

.lngbtnmain {
  font-size: 16px;
}

.gridsun {
  margin-top: 5rem;
}

.boldfont {
  font-family: 'TomatoGroteskbold' !important;
}

.link {
  color: black;
}

.samesize {
  // width: 261px;
  // height: 261px;
}

.map-pointer {
  cursor: pointer !important;
}

.emojibtn {
  background: none !important;
  border-radius: 25px !important;
  padding: 0 !important;
}

.dnone {
  display: none;
}

.RighrArrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #5b5b5b;
  padding-bottom: 0;
  display: none;
}

.leftrArrow-feature {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #5b5b5b;
  padding-bottom: 0;
  cursor: pointer;
}

.RighrArrow-feature {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #5b5b5b;
  padding-bottom: 0;
  cursor: pointer;
}

.RighrArrow.arrow-right-brand {
  padding-bottom: 30px;
  top: 0%;
  display: inline;
}

.LeftArrow {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #5b5b5b;
  padding-bottom: 0;
  display: none;
}

.LeftArrow.arrow-left-brand {
  @media (max-width: 1024px) {
    padding-bottom: 30px;
    left: 88%;
    top: 0%;
    display: inline;
  }

  padding-bottom: 30px;
  left: 95%;
  top: 0%;
  display: inline;
}

.search-bar-web {
  .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    height: 10px !important;
    width: 380px !important;
    background-color: #1f1f1f !important;
    color: white !important;
    border-radius: 8px;
  }

  .css-9vfzmq {
    height: 52px !important;
    width: 380px !important;
    background-color: #1f1f1f !important;
    color: white !important;
    border-radius: 8px;
  }

  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root:focus-visible {
    border: none !important;
    outline: none !important;
  }
}

.after-search {
  position: absolute;
  top: 50px;
  left: 170px;
  z-index: 1;
  background-color: #121212;
  padding-top: 0px;
  // padding-bottom: 15px;
  border-radius: 15px;
  padding: 20px;
  // opacity: 0;
  // visibility: hidden;
  // transition: all ease-in-out 0.5s;
  // &.active {
  //   opacity: 1;
  //   visibility: visible;
  //   top: 50px;
  // }
}

.search-details {
  background-color: #121212 !important;
  padding: 10px;
  border-radius: 3px;
}

// mediaquery
@media (max-width: 1024px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 10px !important;
  }

  .lngbtnmain {
    font-size: 13px;
  }

  .left_content_container {
    width: 500px !important;
    top: 30px !important;
    left: 60px;
    padding: 5px 45px;
  }

  .logo {
    width: 149px;
    height: 47px;
  }
}

@media (max-width: 769px) {
  .dnone {
    display: flex;
  }

  .headingh1 {
    margin-top: 1rem;
  }

  .right-column {
    width: 75% !important;
  }

  .contntbox {
    width: 100% !important;
  }

  .imagebox {
    width: 100% !important;
    // padding-top: 12px;
  }

  .left_content_container {
    position: unset !important;
    width: 100% !important;
    padding-left: 39px !important;
  }

  .imagecontainer {
    position: unset !important;
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .formsection {
    width: 337px !important;
  }

  .left-column {
    max-width: unset !important;
  }

  .left-column {
    margin-left: 0rem !important;
  }

  .quickimg {
    max-width: 722px !important;
  }

  .bookbutton {
    margin-bottom: 2rem !important;
  }

  .spleft {
    margin-top: 9rem !important;
  }
}

@media (max-width: 414px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 9px !important;
  }
}

@media (max-width: 426px) {
  .hidebtn1 {
    display: none;
  }

  .marBotm {
    margin-bottom: 35px;
  }

  .CardTransform {
    .Card-content {
      padding: 0px !important;
    }
  }

  .jobdetails {
    width: 60%;
  }

  .maindivrow {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  .gridsun {
    margin-top: 0px;
  }

  .btnsize {
    font-size: 13px;
  }

  .abtpadding {
    padding: 1rem;
  }

  .EstimateContainer {
    .css-1yxm0zd-MuiPaper-root-MuiCard-root {
      margin: 10px;
      box-shadow: none;
    }
  }

  .headingh1 {
    margin-top: 0rem;
  }

  .css-v5sfno-MuiPaper-root-MuiCard-root {
    margin-left: 18px;
    margin-right: 18px;
  }

  .bookbutton {
    font-size: 11px;
    width: 176px;
  }

  .lngbtn {
    font-size: 12px;
    // width: 226px !important;
    width: auto;
  }

  .lngbtnmain {
    // font-size: 11px;
    width: auto;
  }

  .left_content_container {
    padding: 23px 18px;
  }

  .lngpgicon {
    // margin-right: 3px;
  }

  .right-column {
    width: 100% !important;
  }

  .left-column {
    width: 100% !important;
  }

  .left-column {
    width: 100% !important;
  }

  .rptop {
    margin-top: 2rem;
  }

  .mbbtn {
    width: 153px !important;
  }

  .custom-inputlabel {
    font-size: 12px;
  }

  .custom-textfield input {
    font-size: 12px;
  }

  .css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 10px;
  }

  .request-btn {
    font-family: 'Oxanium';
  }

  // .request-btn-web {
  //   font-size: 45px !important;
  //   width: 500px !important;
  // }

  // .footerlogo {
  //   opacity: 0.3;
  // }
  .spleft {
    margin-top: 0rem !important;
  }

  .left-column {
    padding-left: 18px !important;
    padding-right: 15px !important;
  }

  .gridsun {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .bookbutton {
    margin-bottom: unset !important;
  }

  .quickimg {
    max-width: 580px !important;
  }

  .landgmainsection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px;
  }

  .Card-content {
    margin: 0px;
    padding: 0px;
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }

  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 10px !important;
  }

  .logo {
    width: auto;
    height: auto;
  }

  .Transformmain-typo {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .card-content {
    font-size: 18px !important;
  }

  .zcareimg {
    width: 52px !important;
    height: 60px !important;
  }

  .transparentdiv {
    width: 113px !important;
    height: 113px !important;
    // top:28% !important;
  }

  .contentsection {
    padding: 2rem 1.5rem !important;
  }

  .formsection {
    width: 394px !important;
    padding-top: 0rem !important;
  }
}

@media (max-width: 384px) {
  .lngbtn {
    font-size: 9px !important;
  }
}

@media (max-width: 358px) {
  .hidebtn {
    display: none;
  }
}

@media (max-width: 400px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 9px !important;
  }
}

@media (max-width: 345px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 7px !important;
  }
}

@media (max-width: 390px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 8px !important;
  }

  .lngbtnmain {
    // font-size: 9px;
  }

  .formsection {
    // width: 343px !important;
  }

  .logo {
    width: 80px;
    height: 50px;
  }
}

@media (max-width: 321px) {
  .jobdetails {
    width: 55%;
    margin-right: 1rem;
  }

  .zeal-btn-solid {
    font-size: 10px;
  }

  .mbbtn {
    font-size: 13px;
  }

  .lngbtn {
    font-size: 10px;
  }

  .select {
    font-size: 10px;
  }

  .click,
  .book {
    font-size: 14px;
  }

  .footerlogo {
    height: 72px !important;
    width: 156px;
  }

  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 6px !important;
  }

  .lngbtnmain {
    // font-size: 6px;
  }

  .formsection {
    width: 287px !important;
  }

  .lngpgicon {
    margin-right: 11px;
  }

  .lngbtn {
    text-align: center;
  }
}

// #main-body-sidebar-active {
// }
#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.session-table {
  td {
    vertical-align: top;
  }
}

.product-measurement-input {
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0;
  }

  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
    color: var(--grey-color);
  }
}

.my-DateCalendar {
  // live
  .MuiPickersDay-dayWithMargin,
  // other dates
  .MuiPickersDay-dayWithMargin.Mui-selected,
  // selected date
  .MuiPickersDay-dayWithMargin.Mui-disabled,
  //  disabled dates
  .MuiPickersDay-hiddenDaySpacingFiller,
  // empty date
  .MuiDayCalendar-weekDayLabel,
  // week label
  // dev
  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root,
  // selected date
  .css-ka6agc-MuiButtonBase-root-MuiPickersDay-root,
  // other dates and disabled
  .css-1602a6u-MuiPickersDay-root,
  // empty date
  .css-1n0erti-MuiTypography-root-MuiDayCalendar-weekDayLabel // week label
  {
    font-size: 15px !important;
    width: 60px !important;
    height: 60px !important;

    @media (max-width: 500px) {
      font-size: 13px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root:hover,
  .MuiPickersDay-dayWithMargin:hover,
  .css-ka6agc-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: #ffd4cc !important;
  }

  //
  .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition,
  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    // date container
    min-height: 400px !important;
  }

  .MuiDateCalendar-root,
  .css-1r8uv9g-MuiDateCalendar-root {
    max-height: 520px !important;
  }

  .MuiPickersDay-dayWithMargin.Mui-selected,
  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: var(--primary-color) !important;
  }

  .MuiPickersCalendarHeader-root,
  .css-nk89i7-MuiPickersCalendarHeader-root {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 65px;
    padding-right: 40px;

    @media (max-width: 500px) {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 70px;
      padding-right: 45px;
    }
  }
}

.floating-card {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.5s;
  bottom: 0px;

  &.active {
    opacity: 1;
    visibility: visible;
    bottom: 55px;
  }
}

.custom-textfield-booking {
  &.location {
    .MuiInputBase-root {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      padding-left: 6px;
    }

    .css-12vka4q-MuiFormLabel-root-MuiInputLabel-root {
      top: 7px !important;
    }
  }
}

.search_map {
  position: absolute;
  top: 10px;
  left: 220px;
  width: 100%;
  max-width: 400px;
  z-index: 10;

  @media (max-width: 500px) {
    position: absolute;
    top: 60px;
    left: 5px;
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }
}

.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  // border-radius: 5px;
}

.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 35px;
  width: 40px;
  border-radius: 5px;

  span {
    height: 20px !important;
    width: 20px !important;
  }
}

.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}

.upgrade-video {
  transform: scale(2.5);
}

.infinite-scroll::-webkit-scrollbar {
  display: none;
}

.search_page_wrap {
  background-color: #26282a;
  height: 100vh;
}

.search_page_wrap .myMobileSearchList {
  top: 52px;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
}
